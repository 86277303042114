import { StyledArticle } from '../templates/Article';

const NotFound = () => {
  return (
    <StyledArticle>
      404.ページが見つかりませんでした。
    </StyledArticle>
  )
}

export default NotFound